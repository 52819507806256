import ReactMarkdown from "react-markdown";
import remarkPluginBreaks from "remark-breaks";

import { TAsyncResult } from "../useAsyncValue.js";
import { TRoute } from "../types.js";
import { imprintMarkdown } from "./imprintMarkdown.js";

export function ImprintTitle({
  dataResult,
  routeParams,
}: {
  dataResult: TAsyncResult<null>;
  routeParams: Record<string, unknown>;
}) {
  return <>Impressum</>;
}

const remarkPlugins = [remarkPluginBreaks];

export function ImprintContent(props: {
  dataResult: TAsyncResult<null>;
  routeParams: Record<string, unknown>;
}) {
  return (
    <>
      <ReactMarkdown remarkPlugins={remarkPlugins}>
        {imprintMarkdown}
      </ReactMarkdown>
    </>
  );
}

export const imprintRoute: TRoute<null, Record<string, unknown>> = {
  path: "/imprint",
  Title: ImprintTitle,
  Content: ImprintContent,
  authenticated: false,
};
