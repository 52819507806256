import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  Select,
  Input,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { MoreHoriz } from "@mui/icons-material";
import { useState, SyntheticEvent, useMemo } from "react";
import {
  TGetResourceTypesResponse,
  updateResourceType,
  useAPI,
} from "../api.js";
import { DefaultForm } from "../form.js";
import { useController, useForm } from "react-hook-form";

const DialogButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

type Props = {
  resourceTypes: TGetResourceTypesResponse;
  resourceType: string;
  tenantKey: string;
  locationID: string;
  doorID: string;
};

const CUSTOM_VALUE = "__custom__";

type ResourceTypeFormData = {
  resourceTypeOption: string;
  customResourceType: string;
};

export default function ResourceTypeDialog({
  resourceTypes,
  resourceType,
  tenantKey,
  locationID,
  doorID,
}: Props) {
  const defaultValues = useMemo<ResourceTypeFormData>(() => {
    const isStandardResourceType =
      resourceTypes.tenantResourceTypes.includes(resourceType);
    return {
      resourceTypeOption: resourceTypes.allowCustom
        ? isStandardResourceType
          ? resourceType
          : CUSTOM_VALUE
        : isStandardResourceType
          ? resourceType
          : "",
      customResourceType: isStandardResourceType ? "" : resourceType,
    };
  }, [
    resourceType,
    resourceTypes.allowCustom,
    resourceTypes.tenantResourceTypes,
  ]);

  const form = useForm<ResourceTypeFormData>({ defaultValues });
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const api = useAPI();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (formData: ResourceTypeFormData) => {
    await updateResourceType(
      api,
      tenantKey,
      locationID,
      doorID,
      formData.resourceTypeOption === CUSTOM_VALUE
        ? formData.customResourceType
        : formData.resourceTypeOption,
    );
    setOpen(false);
    window.location.reload();
  };

  const selectController = useController<ResourceTypeFormData>({
    name: "resourceTypeOption",
    control: form.control,
    defaultValue: defaultValues.resourceTypeOption,
  });

  const customResourceTypeController = useController<ResourceTypeFormData>({
    name: "customResourceType",
    control: form.control,
    defaultValue: defaultValues.customResourceType,
    disabled: selectController.field.value !== CUSTOM_VALUE,
  });
  console.log(customResourceTypeController);

  const handleClose = (event: SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  return (
    <>
      <DialogButton onClick={handleClickOpen}>
        <MoreHoriz />
      </DialogButton>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DefaultForm
          form={form}
          defaultValues={defaultValues}
          onSubmit={handleSubmit}
        >
          <DialogTitle>{t("location_details.resource_type")}</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: 300,
                width: "50vh",
                rowGap: (t) => t.spacing(2),
              }}
              alignItems="stretch"
            >
              <FormControl>
                <Select {...selectController.field}>
                  {resourceTypes.allowCustom && (
                    <MenuItem value={CUSTOM_VALUE}>{t("Custom...")}</MenuItem>
                  )}

                  {resourceTypes.tenantResourceTypes.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                {resourceTypes.allowCustom && (
                  <Input
                    placeholder={t("Custom resource type")}
                    {...customResourceTypeController.field}
                  />
                )}
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <DialogButton onClick={handleClose}>
              {t("location_details.cancel")}
            </DialogButton>
            <DialogButton type="submit">
              {t("location_details.ok")}
            </DialogButton>
          </DialogActions>
        </DefaultForm>
      </Dialog>
    </>
  );
}
