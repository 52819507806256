import { Card, CardContent, CardHeader, List } from "@mui/material";
import { styled } from "@mui/material/styles";

import { TOrder$TikiRental$RentalItem } from "../api.js";
import { DataListItem } from "../DataListItem.js";
import { useTranslation } from "react-i18next";

const PREFIX = "RentalItemCard";

const classes = {
  dataCard: `${PREFIX}-dataCard`,
  dataCardContent: `${PREFIX}-dataCardContent`,
  dataCardSubHeading: `${PREFIX}-dataCardSubHeading`,
  dataCardListItem: `${PREFIX}-dataCardListItem`,
  dataCardHeader: `${PREFIX}-dataCardHeader`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.dataCard}`]: {
    flex: "1",
    margin: theme.spacing(1),
    minWidth: "min(300px, 100%)",
  },

  [`& .${classes.dataCardContent}`]: {
    paddingLeft: "0",
    paddingRight: "0",
  },

  [`& .${classes.dataCardSubHeading}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.dataCardListItem}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.dataCardHeader}`]: { paddingBottom: "0" },
}));

export function RentalItemCard({
  rentalItem,
}: {
  rentalItem: TOrder$TikiRental$RentalItem;
}) {
  const { t } = useTranslation();

  return (
    <StyledCard className={classes.dataCard}>
      <CardHeader
        title={`${t("order_details.tiki_rental_item")} #${rentalItem.itemID}`}
        className={classes.dataCardHeader}
      />
      <CardContent className={classes.dataCardContent}>
        <List>
          {rentalItem.resources.map((rentalResource) => (
            <>
              <DataListItem
                value={rentalResource.doorID}
                label={t("common.door")}
                className={classes.dataCardListItem}
              />
              <DataListItem
                value={rentalResource.resourceType}
                label={t("order_details.resource_type")}
                className={classes.dataCardListItem}
              />
              <DataListItem
                value={rentalResource.containerID}
                label={t("order_details.container_id")}
                className={classes.dataCardListItem}
              />
            </>
          ))}
        </List>
      </CardContent>
    </StyledCard>
  );
}
