import { Context, ReactNode, createContext, useContext, useMemo } from "react";
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";

type DefaultFormProps<
  TFieldValues extends FieldValues,
  TContext = unknown,
> = DefaultFormProviderProps<TFieldValues, TContext> & {
  onSubmit?: SubmitHandler<TFieldValues>;
};

type CustomFormContext<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues>;
  defaultValues: TFieldValues;
};

const customFormContext = createContext<CustomFormContext<FieldValues> | null>(
  null,
);

type DefaultFormProviderProps<
  TFieldValues extends FieldValues,
  TContext = unknown,
> = {
  form: UseFormReturn<TFieldValues, TContext>;
  defaultValues: TFieldValues;
  children: ReactNode;
};

export const DefaultFormProvider = <
  TFieldValues extends FieldValues,
  TContext = unknown,
>({
  form,
  defaultValues,
  children,
}: DefaultFormProviderProps<TFieldValues, TContext>) => {
  const value = useMemo<CustomFormContext<TFieldValues>>(
    () => ({ form, defaultValues }),
    [defaultValues, form],
  );
  const { Provider } = customFormContext as Context<
    CustomFormContext<TFieldValues>
  >;
  return <Provider value={value}>{children}</Provider>;
};

export const DefaultForm = <
  TFieldValues extends FieldValues,
  TContext = unknown,
>({
  form,
  children,
  onSubmit,
  defaultValues,
  ...otherProps
}: DefaultFormProps<TFieldValues, TContext>) => {
  return (
    <DefaultFormProvider form={form} defaultValues={defaultValues}>
      <form
        onSubmit={onSubmit ? form.handleSubmit(onSubmit) : undefined}
        {...otherProps}
      >
        {children}
      </form>
    </DefaultFormProvider>
  );
};

export const useDefaultFormContext = <TFieldValues extends FieldValues>() => {
  const result = useContext(
    customFormContext as Context<CustomFormContext<TFieldValues> | null>,
  );

  if (!result) {
    throw new Error(`Expected form context to be present`);
  }

  return result;
};
