import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  Box,
  FormControl,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { TLocation } from "../api.js";
import LanguageSelector from "../i18n/LanguageSelector.js";
import { Add, Search } from "@mui/icons-material";
import { ChangeEventHandler, useCallback, useEffect, useState } from "react";

const LocationSidebarContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  minWidth: "min(250px, 50%)",
  backgroundColor: theme.palette.secondary.dark,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "20px",
  padding: "20px 15px 10px 15px",
  overflow: "hidden",
}));
const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.light,
  fontWeight: "bold",
  fontSize: "2rem",
}));
const LocationSelectContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  overflow: "scroll",
}));
const LocationsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));
const LocationLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.light,
  textDecoration: "none",
  marginBottom: theme.spacing(1),
  "&:hover": {
    textDecoration: "underline",
  },
  fontSize: "1.5rem",
  lineHeight: "1.75rem",
}));
const AddLocationIcon = styled(Add)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: "2.5rem",
  lineHeight: "2.5rem",
}));

const LocationFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(3),
}));

const LocationSearchInput = styled(Input)(({ theme }) => ({
  color: theme.palette.primary.light,
  [`&::before`]: {
    borderBottomColor: theme.palette.primary.light,
  },
}));

const LocationSearchIcon = styled(Search)(({ theme }) => ({
  color: theme.palette.primary.light,
}));

type Props = {
  locations: TLocation[];
  routeParams: { tenantKey: string; locationID: string; areaID: string };
};

export const LocationSidebar = ({ locations, routeParams }: Props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [filteredLocations, setFilteredLocations] =
    useState<TLocation[]>(locations);
  const handleSearchInputChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      const filteredData: TLocation[] = locations.filter((location) => {
        return location.name
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      setSearchValue(event.target.value);
      setFilteredLocations(filteredData);
    },
    [locations],
  );

  useEffect(() => setFilteredLocations(locations), [locations]);

  return (
    <LocationSidebarContainer>
      <LocationSelectContainer>
        <Title>{t("common.location")}</Title>
        <LocationFormControl>
          <LocationSearchInput
            id="order-details-page-search-input"
            value={searchValue}
            placeholder={t("common.location")}
            startAdornment={
              <InputAdornment position="start">
                <LocationSearchIcon />
              </InputAdornment>
            }
            onChange={handleSearchInputChange}
          />
        </LocationFormControl>
        <LocationsContainer>
          {filteredLocations.map((location) => (
            <LocationLink
              key={location.locationID}
              to={`/tenants/${routeParams.tenantKey}/overview/location/${location.locationID}`}
            >
              {location.name}
            </LocationLink>
          ))}
        </LocationsContainer>

        {/* TODO: Implement add location */}
        <AddLocationIcon onClick={() => {}} />
      </LocationSelectContainer>

      <Box>
        <LanguageSelector />
      </Box>
    </LocationSidebarContainer>
  );
};
