import { TRoute } from "../types.js";

export const tenantLocationsListRoute: TRoute<null, { tenantKey: string }> = {
  path: "/tenants/:tenantKey/locations",
  redirect: (params, selectedTenant) =>
    selectedTenant ? `/tenants/${selectedTenant}/overview` : null,
};

export const locationsListRoute: TRoute<null, Record<string, unknown>> = {
  path: "/locations",
  redirect: (params, selectedTenant) =>
    selectedTenant ? `/tenants/${selectedTenant}/overview` : null,
};
