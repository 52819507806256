import { Typography , Card , CardHeader , CardContent , Divider , List } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMemo } from "react";

import { TOrder$RegiondoOrder$Item, TOrder$TikiOrder } from "../api.js";
import { DataListItem } from "../DataListItem.js";
import { useTranslation } from "react-i18next";

const PREFIX = "OrderItemCard";

const classes = {
  dataCard: `${PREFIX}-dataCard`,
  dataCardContent: `${PREFIX}-dataCardContent`,
  dataCardSubHeading: `${PREFIX}-dataCardSubHeading`,
  dataCardListItem: `${PREFIX}-dataCardListItem`,
  dataCardHeader: `${PREFIX}-dataCardHeader`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.dataCard}`]: {
    flex: "1",
    margin: theme.spacing(1),
    minWidth: "min(300px, 100%)",
  },

  [`& .${classes.dataCardContent}`]: {
    paddingLeft: "0",
    paddingRight: "0",
  },

  [`& .${classes.dataCardSubHeading}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.dataCardListItem}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.dataCardHeader}`]: { paddingBottom: "0" },
}));

export function OrderItemCard({
  regiondoOrderItem,
  tikiOrder,
}: {
  regiondoOrderItem: TOrder$RegiondoOrder$Item;
  tikiOrder: TOrder$TikiOrder;
}) {
  const { t } = useTranslation();
  const tikiOrderItem = useMemo(
    () =>
      tikiOrder.items.find(
        (tikiOrderItem) =>
          tikiOrderItem.itemID === regiondoOrderItem.unique_item_id,
      ),
    [regiondoOrderItem.unique_item_id, tikiOrder.items],
  );
  if (!tikiOrderItem) {
    throw new Error(
      `${"message.no_regiondo_order_found"} ${regiondoOrderItem.unique_item_id}`,
    );
  }

  return (
    <StyledCard className={classes.dataCard}>
      <CardHeader
        title={`${t("common.item")} #${regiondoOrderItem.unique_item_id}`}
        className={classes.dataCardHeader}
      />
      <CardContent className={classes.dataCardContent}>
        <List>
          <DataListItem
            value={regiondoOrderItem.unique_item_id}
            label={t("order_details.regiondo_item_id")}
            className={classes.dataCardListItem}
          />
          <DataListItem
            value={
              tikiOrderItem
                ? formatDateTime(tikiOrderItem.eventStartDateTime)
                : t("common.unknown")
            }
            label={t("common.start")}
            className={classes.dataCardListItem}
          />
          <DataListItem
            value={
              tikiOrderItem
                ? formatDateTime(tikiOrderItem.eventEndDateTime)
                : t("common.unknown")
            }
            label={t("common.end")}
            className={classes.dataCardListItem}
          />
          <DataListItem
            value={regiondoOrderItem.ticket_name}
            label={t("order_details.regiondo_ticket_name")}
            className={classes.dataCardListItem}
          />
          <DataListItem
            value={regiondoOrderItem.ticket_variation}
            label={t("order_details.regiondo_ticket_variation")}
            className={classes.dataCardListItem}
          />
          <DataListItem
            value={regiondoOrderItem.ticket_option}
            label={t("order_details.regiondo_ticket_option")}
            className={classes.dataCardListItem}
          />
        </List>
      </CardContent>
      <Divider />
      <CardContent className={classes.dataCardContent}>
        <Typography className={classes.dataCardSubHeading} variant="h6">
          {`${"order_details.resources"} (${regiondoOrderItem.resources.length})`}
        </Typography>
        <List>
          {regiondoOrderItem.resources.map((resource) => (
            <>
              <DataListItem
                value={resource.resource_name}
                label={t("order_details.regiondo_ticket_option")}
                className={classes.dataCardListItem}
              />
            </>
          ))}
        </List>
      </CardContent>
    </StyledCard>
  );
}

function formatDateTime(dateTimeString: string) {
  return `${new Date(dateTimeString).toLocaleDateString()} ${new Date(
    dateTimeString,
  ).toLocaleTimeString("de", {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
}
