import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { CssBaseline } from "@mui/material";
import * as Sentry from "@sentry/react";

import { theme } from "./theme.js";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import { Auth0ProviderWithHistory } from "./auth.js";
import { GlobalStyles, StyledEngineProvider, ThemeProvider } from "@mui/system";
import "./i18n/i18n.js";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.httpContextIntegration],
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAME_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_SAME_RATE)
    : 0.5,
});

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Root element not found");
}

const reactRoot = createRoot(rootElement);
reactRoot.render(
  <React.StrictMode>
    <CssBaseline />
    <GlobalStyles
      styles={{
        body: { color: "#3B3C3D" },
      }}
    />
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </StyledEngineProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(() => {});
